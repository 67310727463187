import isEqual from "fast-deep-equal";
import { pluckFirst, useObservable, useObservableState } from "observable-hooks";
import { useRef } from "react";
import { Observable, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs";
import { useSyncExternalStoreWithSelector } from "use-sync-external-store/shim/with-selector";
import { Store } from "../core";

type StoreValue<T extends Store<any>> = ReturnType<T["get"]>;

export const useStore = <T extends Store<any>, S = StoreValue<T>>(
  store: T,
  selector?: (v: StoreValue<T>) => S,
  debug = false,
) => {
  const store$ = useObservable(pluckFirst, [store]);
  const value$ = useObservable(() =>
    store$.pipe(
      switchMap((s) => s.value$),
      map((v) => (selector ? selector(v) : v)),
      tap((v) => debug && console.log("useStore", v)),
      distinctUntilChanged(isEqual),
      // share(),
    ),
  );
  const value = useObservableState(value$, selector && store?.get() ? selector(store?.get()) : store?.get());
  return value as S;
};

export const useOptionalStore = <T extends Store<any>, S = StoreValue<T>>(
  store?: T,
  selector?: (v: StoreValue<T>) => S,
  debug = false,
) => {
  const store$ = useObservable(pluckFirst, [store]);
  // const selector$ = useObservable(pluckFirst, [selector]);
  const value$ = useObservable(() =>
    store$.pipe(
      filter((s): s is T => s !== undefined),
      switchMap((s) => s.value$),
      map((value) => (selector ? selector(value) : value)),
      tap((v) => debug && console.log("useStore", v)),
      distinctUntilChanged(isEqual),
      // share(),
    ),
  );
  const value = useObservableState(value$, selector && store?.get() ? selector(store?.get()) : store?.get());
  return value as S | undefined;
};

export const useObservableValue = <T, S = T>(o$: Observable<T>, selector?: (v: T) => S) => {
  const obs$ = useObservable(pluckFirst, [o$]);
  const value$ = useObservable(() =>
    obs$.pipe(
      filter((v): v is Observable<T> => v !== undefined),
      switchMap((v) => v),
      filter((s): s is T => s !== undefined),
      map((v) => (selector ? selector(v) : v)),
      distinctUntilChanged(isEqual),
    ),
  );
  const value = useObservableState(value$, undefined);
  return value as S;
};

export const useOptionalObservableValue = <T, S = T>(o$?: Observable<T>, selector?: (v: T) => S) => {
  const obs$ = useObservable(pluckFirst, [o$]);
  const value$ = useObservable(() =>
    obs$.pipe(
      filter((v): v is Observable<T> => v !== undefined),
      switchMap((v) => v),
      filter((s): s is T => s !== undefined),
      map((v) => (selector ? selector(v) : v)),
      distinctUntilChanged(isEqual),
    ),
  );
  const value = useObservableState(value$, undefined);
  return value as S | undefined;
};

export function useStoreWithDynamicSelector<T extends Store<any>, S = StoreValue<T>>(
  store: T | undefined,
  selector: (state: StoreValue<T>) => S | undefined,
  equalityFn: (a: S | undefined, b: S | undefined) => boolean = isEqual,
): S | undefined {
  const storeRef = useRef(store);
  storeRef.current = store;

  return useSyncExternalStoreWithSelector(
    (callback: () => void) => {
      if (!storeRef.current) return () => {};
      const subscription = storeRef.current.value$.subscribe(callback);
      return () => subscription.unsubscribe();
    },
    () => storeRef.current?.get(),
    () => storeRef.current?.get(),
    (state) => (state !== undefined ? selector(state) : undefined),
    equalityFn,
  );
}
